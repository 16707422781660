.icon {
    margin-right: 5px;
}

.icon.red {
    color: red;
}

.icon.green {
    color: green;
}