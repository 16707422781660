div#body-wrapper {
  margin-top: 5em;
}
/* 
div#body-wrapper:before {
  content: " ";
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.08;
  background: url("thumbnail_background_zandvoort.png") no-repeat center;
  background-size: contain;
  z-index: 0;
} */

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 5em; /* Set the fixed height of the footer here */
  line-height: 5em; /* Vertically center the text there */
  background-color: #f5f5f5;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border-top: 1px solid #dee2e6 !important;
  z-index: 15;
}

div#content-div {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5em;
}


@media (min-width: 1024px) {
    .top-navbar {
      display: inline-flex !important;
    }
}