@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Inter var, ui-sans-serif, system-ui, -apple-system,
        BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol", "Noto Color Emoji";
    font-variation-settings: normal;
}

a {
    text-decoration: none !important;
}

html, body, #root, #root > div, #app, #app > div {
    @apply min-h-full;
    @apply bg-gray-100 !important;
}